.posts {
  .post-container {
    flex-wrap: wrap;
      max-width: 1280px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

  }
  a {
    width: 40vw;
    @media screen and (min-width: 1600px) {
      width: 37vw;

    }
    @media screen and (min-width: 1700px) {
      width: 33vw;

    }
    @media screen and (min-width: 1900px) {
    width: 31vw;

  }
    @media screen and (min-width: 2000px) {
      width: 24vw;

    }
    @media screen and (max-width: 1024px) {
      width: 90vw;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .single-post {
    height: 604px;
    z-index: 1;
    position: relative;
    background-size: cover;
    background-position: center;
    margin-bottom: 34px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.07);
    .post-thumb {
      width: 100%;
      height: 310px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .8s ease-in-out;
      }
    }
    .post-content {
      background-color: white;
      display: inline-block;
      padding: 70px;
      height: 295px;
      .post-meta {
        display: block;
        color: #737373;
        font-family: $circular;
        font-size: 0.6875em;
        font-weight: 500;
        letter-spacing: 1.4px;
        line-height: 14px;
      }
      h4 {
        font-size: 1.875em;
        letter-spacing: -0.29px;
        line-height: 26px;
        margin-top: 14px;
        margin-bottom: 18px;
        transition: color .8s ease-in-out;
      }

      @media screen and (max-width: 768px) {
        padding: 20px;
        height: 280px;
      }
    }
    &:hover {
      .post-thumb {
        img {
          transform: scale(1.2);
        }
      }
      h4 {
        color: $pink;
      }
    }
  }
}


.post {
  h1 {
    font-size: 3em;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  p {
    position: relative;
    z-index: 1;
    img {
      max-width: 100%;
    }
  }
  .hero-image {
    width: 100%;
    position: relative;
    height: auto;
    z-index: 1;
    img {
      width: 100%;
      height: auto;
      //object-fit: cover;
    }
    margin-bottom: 50px;
  }
  .date{
    margin-bottom: 30px;
    color: $pink;
    font-family: $circular;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 30px;
    }
    .hero-image {
      height: auto;
      margin-bottom: 20px;
    }
  }
}

.post-wrap {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  p {
    margin-bottom: 1.5em;
    line-height: 1.8;
  }
  ul,ol {
    li {
      line-height: 1.8;
      margin-bottom: 1.5em;
    }
  }


  h2 {
  margin-top: 1.8em;
    font-size: 2em;
  }
  h3 {
    font-size: 1.4em;
  }

  @media screen and (max-width: 800px) {
    max-width: 600px;
  }

  @media screen and (max-width: 750px) {
    max-width: 400px;
  }
  @media screen and (max-width: 430px) {
    max-width: 320px;
  }

}

body.post {
  .line-bg {
    display: none;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 90%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #CCCCCC;
}
