//general
* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  position: relative;
  //scroll-behavior: smooth;

}

body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.site-content {
  opacity: 0;
  &.loaded {
    opacity: 1;
  }
}
a {
  text-decoration: none;
  color: $body_text_color;
  outline: none;
  -webkit-appearance: none;


  &:visited {
    color: $body_text_color;
  }

}

button {
  outline: none;
  -webkit-appearance: none;
}

input[type="submit"] {
  -webkit-appearance: none;
}
// Flex properties
.flex-wrapper {
  display: flex;

}

.tablet-landscape-flex-column {
  @media screen and (max-width: 1024px) {
    // flex-direction: column;
  }
}
.mobile-flex-column {
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.mobile-flex-column-reverse {
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.wrap {
  flex-wrap: wrap;
}

.vertical-align-center {
  align-items: center;
}

.vertical-align-start {
  align-items: flex-start;
}

.justify-centered {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.border32 {
  margin: 32px;
}

.padding24 {
  padding: 24px;
}

.padding40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding70 {
  padding: 70px;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}

.padding100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}


.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}


.grey_bg {
  background-color: $grey;
}

.gradient_bg {
  background: $linear-gradient;
  background-size: 200% auto !important;
}

.image_bg {
  background-image:  url(/assets/images/socialimpact.png);
  background-size: cover;
  background-repeat: no-repeat; /* Impedisce la ripetizione dell'immagine */
}

.gradient_bg_opposite {
  background: $linear-gradient-opposite;
  background-size: 200% auto !important;
}


.white {
  color: #fff;
}

.scroll-down {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 10px;

  .elements {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 6px;
    height: 100%;
    margin-left: 25px;

    span {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    top: 50%;
    left: 91%;
    transform: translateY(-50%);
  }
}

.dot {
  width: 6px;
  height: 6px;
  background-color: $body_text_color;
  border-radius: 100%;

}

.dash {
  width: 1px;
  height: 13px;
  background-color: $body_text_color;
  margin: 10px 0;

}

.scroll {
  transform: rotate(-90deg);
  margin-right: 4px;
  font-family: $circular;
  font-size: 11px;
  letter-spacing: 1px;
}

.container-padding {
  padding-left: 100px;
  padding-right: 100px;
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }


}

.container-padding-60 {
  padding-left: 60px;
  padding-right: 60px;
  @media screen and (max-width: 768px) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

.button-container {
  display: block;
  margin-top: 40px;
}

.social-conversa {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  width: 18px;
  height: auto;
  z-index: 1;
  a {
    margin-bottom: 5px;
    display: inline-block;
  }
  img {

    width: 18px;
    height: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    display: inline-block;
    position: absolute;
    bottom: 0;
    width: auto;
    img {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
}


.copyright {
  font-size: 0.6875em;
  margin-right: 140px;
  margin-left: 140px;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    margin-right: 7.5%;
    margin-left: 7.5%;
    text-align: center;
  }
}

.hidden {
  display: none;
}

.maxwidth80 {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.shadow {
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.36);
}

@media screen and (max-width: 768px) {
  .align-left-mobile {
    text-align: left;
  }
  .align-center-mobile {
    text-align: center;
  }

  .padding-mobile {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}


//cards
.card {
  width: 48%;
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  z-index: 1;
  border-radius: 6px;
  overflow: hidden;

  h3, p {
    display: block;
  }

  h3 {
    margin-top: 15px;
    margin-bottom: 55px;
    font-size: 2.8125em;
    font-weight: bold;
    letter-spacing: -0.44px;

  }

  p {
    font-family: $barlow;
  }

  .btn {
    display: inline-block;
    width: 200px;
  }

  .os {
    width: 40%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    @media screen and (max-width: 1024px) {
      width: 90%;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1280px) {
      .btn {
        width: auto;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.downloads {

  p {
    font-size: 0.8125em;
    color: #757575;
    letter-spacing: 0.01px;
    line-height: 0.9375em
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
    &:before {
      max-width: 100vw;
    }
  }
}

#projects {
  border-radius: 6px;
  background: linear-gradient(128.5deg, #3023AE 0%, #C86DD7 100%);
}

#versions {
  border-radius: 6px;
  background: linear-gradient(90deg, #FD7059 0%, #FB3385 100%);
}

.utilities {
  .card {
    padding: 60px 70px;
    @media screen and (max-width: 768px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  h3 {
    margin-bottom: 0;
    margin-top: 40px;
    height: 100px;
    @media screen and (max-width: 768px) {
      height: auto;
    }
  }
  p {
    letter-spacing: 0.01px;
    line-height: 1.25em;
    padding: 26px 0 120px;
  }
}



.hero_wrapper {
  &.align-center {
    p {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
#desktop-software, #case-studies {
  position: relative;
  .align-center {
    p {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.free-trial {
  h1 {
    margin-top: 0;
  }
  .card {
    width: 30%;
    height: 478px;
    z-index: 1;
    border-radius: 6px;
    a {
      display: block;
      height: 100%;
      padding: 30px 0 60px;
      * {
      }
      .card-img {
        width: 100%;
        height: 250px;
        img {
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }
      }
      p, h4 {
        padding-left: 30px;
        padding-right: 30px;
      }
      p {
        font-size: 1em;
      }
    }
    @media screen and (max-width: 768px) {
      width: 98%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
    /*    &:last-child {
          .card-img {
            img {margin-left: 26px;
            }
          }
        }*/
  }
}

.trial {
  .flex-wrapper {
    padding-top: 20px;
  }
  .card {
    width: 32%;
    position: relative;
    padding: 294px 20px 75px;
    @media screen and (min-width: 1366px) {
      width: 29%;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
    }
    &:before {
      content: '';
      width: 100%;
      height: 246px;
      position: absolute;
      left: 0;
      top: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:nth-child(1) {
      &:before {
        background-image: url('/assets/images/getting_started.png');
      }
    }
    &:nth-child(2) {
      &:before {
        background-image: url('/assets/images/basics.png');
      }
    }
    &:nth-child(3) {
      &:before {
        background-image: url('/assets/images/animation.png');
        background-position-x: 27px;
        @media screen and (max-width: 768px) {
          left: 100%;
          transform: translate(-100%);
          width: 50%;
        }
      }
    }
  }
  &.container-padding {
    @media screen and (max-width: 1024px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

}



.overlap-bg {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    max-width: 95vw;
    height: 350px;
    background-color: $grey;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: -1;
    @media screen and (max-width: 768px) {
      max-width: 100vw;
    }
  }
}

.hide-overflow {
  overflow: hidden;
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}


.line-bg {
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  top:0;
  left: 0;
  &:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: calc(100% - 30px);
    background-image: url('/assets/images/line.png');
    background-repeat: repeat;
    background-position: center;
    opacity: .12;
    z-index: 1;
    @media screen and (max-width: 768px) {
      background-size: 20%;
    }
  }

}

#os {
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 43px;
  }
}

#feat-logo {
  @media screen and (max-width: 768px) {
    margin-top: -125px;
  }
}

@media screen and (min-width: 1600px) {
  .download {
    .flex-wrapper {
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
}

#versions {
  .btn {
    width: auto;
  }
}


.trial {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}



.download {
  .card {
    h3 {
      margin-bottom: 0;
    }
    p.subtitle {
      margin-bottom: 60px;
      font-family: $circular;
      font-size: 0.75em;
      letter-spacing: 1.52px;
      line-height: 0.9375em;
      text-transform: uppercase;
    }
  }
}

.nav-container {
  .social-conversa {
    @media screen and (max-width: 1024px) {
      left: 50%;
      top: 80%;
      transform: translate(-50%, 0);
      img {
        filter: invert(white, 100%);
      }

    }
  }
}

#banner {
  background: $linear-gradient;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;
  z-index: 99;
  height: 278px;
  .image-container, .btn-container {
    width: 30%;
  }
  .image-container {
    img {
      position: relative;
      left: 0;
      transform: translate(-30%, 25%);

    }
  }
  .btn-container {
    text-align: right;
  }
  .white {
    width: 45%;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 9px;
  }
  p {
    font-weight: 500;
    letter-spacing: 0.01px;
  }

  &.minified {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 99;
    //height: 60px;

    .image-container {
      width: 10%;
      img {
        position: absolute;
        width: 100px;
        left: 0;
        top: 66%;
        transform: translateY(-50%);
      }
    }
    .btn-container {
      a {line-height: 1.7em;}

    }
    .white {
      width: 80%;
    }

    h3 {
      font-size: 1.5em;
      line-height: 1.7em;
    }
    p {
      display: none;
    }
    #close {
      display: none;
    }
  }
/*  @media screen and (max-width: 1024px) {
    .image-container {
      img {
        width: 100%;
        transform: translate(-80%, 40%);
        //transform: translateY(-50%);
      }
    }
    .white {
      width: 65%
    }
    &.minified {
      .white {
        h3 {
          font-size: 1.3em;
        }
      }
      .image-container {
        display: none;
        width: 10%;
        img {
          position: absolute;
          width: 100px;
          //top: 0;
          transform: translateY(-80%);
        }
      }
    }
  }*/
  @media screen and (max-width: 1024px) {
    height: 350px;
    .flex-wrapper {
      flex-wrap: wrap;
    }
    .image-container {
      width: 25%;
      img {
        width: 100%;
        left: -45%;
      }
    }
    .btn-container {
      width: 100%;
    }
    .white {
      width: 75%;
    }
    .btn {
      width: 75%;
    }
    &.minified {
      .flex-wrapper {
        display: flex;
      }
      .container-padding {
        padding-left: 50px;
        padding-right: 50px;
      }

      .image-container {
        display: none;
      }
      .white {
        width: 70%;
        h3 {
          font-size: 1.2em;
        }
      }
      .btn-container {
        width: 30%;
        .btn {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .flex-wrapper {
      flex-wrap: wrap;
    }
    height: auto;
    .image-container {
      width: 100%;
      img {
        width: 50%;
        left: -15px;
        transform: translate(-45%, 0);
      }
    }
    .white {
      width: 100%;
    }
    &.minified {
      .flex-wrapper {
        display: flex;
      }

      .image-container {
        display: none;
      }
      .white {
        width: 70%;
        h3 {
          font-size: 1.2em;
        }
      }
      .btn-container {
        width: 30%;
        .btn {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    height: auto;
   .flex-wrapper {
     flex-wrap: wrap;
   }
    .image-container {
      width: 100%;
      img {
        width: 35%;
        margin-top: 0;
        top: 0;
        left: -20px;
        transform: translate(0, 0);
      }
    }
    .btn, .white {
      width: 100%;
    }
    &.minified {
      .flex-wrapper {
        display: flex;
      }

      .image-container {
        display: none;
      }
      .white {
        width: 70%;
        h3 {
          font-size: 1.2em;
        }
      }
      .btn-container {
        width: 30%;
        .btn {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 660px) {
    height: auto;
    .flex-wrapper {
      flex-wrap: wrap;
    }
    .image-container {
      width: 100%;
      img {
        width: 90%;
        margin-top: 0;
        top: 0;
        left: -20px;
        transform: translate(0, 0);
      }
    }
    .btn-container {
      width: 100%;
    }
    .btn, .white {
      width: 100%;
    }
    &.minified {
      height: auto;
      .flex-wrapper {
        display: block;
      }

      .image-container {
        display: none;
      }
      .white {
        width: 100%;
        h3 {
          line-height: 1;
          text-align: center;
        }
      }
      .btn-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;

      }
    }
  }
}

#close {
  font-size: 40px;
  font-weight: 300;
  color: white;
  position: absolute;
  left: 96%;
  top: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 4;
  @media screen and (max-width: 600px){
    left: 87%;
  }}

.pubLogo {
  width: 77px;
  height: auto;
}
#feat-logo {
  img {
    width: 77px;
  }


}

#faq {
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .flex-wrapper {
      flex-direction: column;
    }
    .col-30, .col-70 {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .download {

    .container-padding {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  #projects, #versions {

      padding: 30px;
  }


}

code{
  background-color: #f6f6f6;
  padding: 5px;
  font-size: 0.9em;
}

blockquote {
  position: relative;
  p {
    font-style: italic
  }
}
blockquote:before {
  content: '';
  width: 1px;
  height: 100%;
  background: $linear-gradient-opposite;
  position: absolute;
  left: -20px;
}
