.footer_cta {
  h3.clip {
    font-size: 4.5em;
    line-height: 1.2;
    margin-top: 40px;
    margin-bottom: 20px;
    text-transform: none;
    @media screen and (max-width: 768px) {
      font-size: 50px;
    }
  }

  img {
    width: 23px;
    height: auto;
  }
 @media screen and (max-width: 768px) {
   .col-100 {
     padding-left: 7.5%;
     padding-right: 7.5%;
   }
 }
  .svg-text {
    p {
      text-align: center;
      margin: auto;
    }
  }
.flex-wrapper {
  border-radius: 6px;
}
}

.footer-container {
  position: relative;
  z-index: 1;
  h3 {
    margin-bottom: 0;
  }

  p {
    margin-top: 6px;
    width: 85%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .input-container {
    @media screen and (min-width: 1025px) {
      float: right;
    }


    input {
      overflow: hidden;
      box-sizing: border-box;
      height: 50px;
      width: 369px;
      border: 1px solid rgba(151, 151, 151, 0.26);
      border-radius: 6px;
      background-color: #F0F0F0;
      font-size: 1em;
      padding-left: 10px;
      margin-right: 15px;
      outline: none;
      margin-bottom: 4px;
      &:focus {
        border-color: $pink;
      }
      &.valid {
        border-color: green;
      }

      @media screen and (max-width: 1279px) {
        width: 60%;
        display: inline-block;
      }
      @media screen and (max-width:640px) {
        width: 90%;
        margin-right: 0;
        margin-bottom: 0;
      }




    }
  }

  .black_btn, #mc-embedded-subscribe {
    background: $body_text_color;
    height: 50px;
    margin: 0;
    width: 180px;
    @media screen and (max-width: 1280px) {
      width: auto;
    }
    @media screen and (max-width: 1279px) {
      width: 30%
    }

    @media screen and (max-width:640px) {
      width: 90%;
      padding: 0;
      margin-right: 0;
      margin-top: 15px;
      display: inline-block;
    }



  }
  .footer_cta, .newsletter {
    position: relative;
    z-index: 1;
  }
}

.footer_menu {
  position: relative;
  margin-left: 140px;
  margin-right: 140px;

  h4 {
    font-size: 0.6875em;
    text-transform: uppercase;
    font-family: $circular;
    letter-spacing: 1.4px;
    line-height: 14px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      font-size: 0.8125em;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: 0.66px;
      font-family: $barlow;
      a:hover {
        color: $pink;
      }
    }
  }
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    margin-left: 100px;
    margin-right: 100px;
  }
@media screen and (max-width: 768px) {
  margin-left: 7.5%;
  margin-right: 7.5%;
  text-align: center;
  ul {
    display: none;
  }

}

  .address {

  }

  .social-conversa {
    position: absolute;
    left: 100%;
    top: 80%;
    @media screen and (max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}


#footer_cta_txt {
  width: 100%;
  text {
    font-size: 74.36px;
    font-weight: bold;
    letter-spacing: 0.03px;
    line-height: 59.28px;
    text-align: center;
    font-family: $barlow;
    @media screen and (max-width: 768px) {

      transform: scale(1.6);
      transform-origin: center;
    }
  }
  @media screen and (max-width: 768px) {
    height: 12vw;
  }
}

.privacy-container {
  margin-top: 14px;
  border-radius: 6px;
  font-size: 12px;
  &.error {
    border: 1px solid $pink;
  }
  #privacy-check {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: auto;



  }
  span {
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 768px) {
      margin-left: 10px;
    }
  }
  a {
    color: $pink;
    &:hover {
      text-decoration: underline;
    }
  }
}

#mce-success-response {
  position: absolute;
  width: 36%;
  font-size: 12px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 6px;
  background-color: rgba(7,170,142,.4);
  @media screen and (max-width: 1024px) {
    position: relative;
    width: 100%;
  }
}
#mce-error-response {
  background-color: $pink;
  color: white;
  padding: 10px 30px;
  margin-top: 20px;
  border-radius: 6px;
}
