/*

.masonry {
  display: flex;
  flex-flow: column wrap;
  max-width: 100%;
  overflow: hidden;
  .item {
    padding: 15px;
    flex: 1 0 auto;
    overflow: hidden;
    width: 100%;
    &.full-width {
      width: 100%;
    }

   @media screen and (min-width: 768px) {
     width: 50%;
       &:nth-child(odd):not(.item-three) {
         order: 1;
       }
       &:nth-child(even):not(.item-three) {
         order: 2;
     }
     &.one-third {
       width: 50%
     }
     &.item-three {
       &:nth-child(1) {
         order: 1;
       }
       &:nth-child(2) {
         order: 2;
       }
       &:nth-child(3) {
         order: 1;
       }
       &:nth-child(4) {
         order: 2;
       }
     }
    }
    @media screen and (min-width: 1024px) {
      &.one-third {
        width: 25%;
      }
      &.item-three {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 2;
        }
        &:nth-child(5) {
          order: 2;
        }

      }

      @media screen and (min-width:1024px) {
        max-height: 37.75rem;
        &.half-height {
          max-height: 18.75rem;
        }
      }

    }

    &.light-text {
      color: #fff
    }
    .content {
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 100px;
      border-radius: 6px;
      background-color: white;
      padding: 20px;
      z-index: 0;
      &:before {
        content: '';
        position: absolute;
        height: auto;
        z-index: -1;
      }
      .category {
        font-size: 0.6875em;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        margin-bottom: 27px;
        display: inline-block;
        font-family: $circular;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 14px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.29px;
        line-height: 26px;
      }
    }



  }
}

*/

.masonry-container {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  padding-bottom: 100px;
  @media screen and (max-width: 1440px) {
    max-width: 90%;
  }
}
.masonry {
    display: grid;
    //grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: minmax(150px,1fr) 1fr 1fr minmax(150px,1fr);
    //grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-rows: minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) auto auto minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(280px,auto) minmax(280px,auto) auto;

    grid-gap: 20px;
  grid-template-areas:
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . ."
          ". . . .";

grid-template-rows: minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) auto auto minmax(300px,auto) minmax(300px,auto) minmax(300px,auto) minmax(280px,auto) minmax(280px,auto) auto;
  @media screen and (max-width: 1024px) {
    grid-gap: 0;
  }
  @media screen and (max-width: 680px) {
  display: block;
  }
  .wr {
    max-width: 50%;
    width: 100%;
  }

  .item {
    width: 100%;
    position: relative;
    @media screen and (max-width: 1200px) {
      padding: 15px;
      flex: 1 0 auto;
      overflow: hidden;
      //width: 50%;


    }
    @media screen and (max-width: 700px) {
      width: 100%;

    }
    &.light-text {
      color: #fff
    }
    .content {
      width: 100%;
      height: 100%;
      position: relative;
      min-height: 100px;
      padding: 20px;
      z-index: 0;
      background-color: white;
      border-radius: 6px;

      &:before {
        content: '';
        position: absolute;
        height: auto;
        z-index: -1;
      }
    }
      .category {
        font-size: 0.6875em;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        margin-bottom: 27px;
        display: inline-block;
        font-family: $circular;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 14px;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: -0.29px;
        line-height: 26px;
      }
  }
}



#item-1 {
  .content {
    padding: 86px 90px 93px 248px;
    &:before {
      background-image: url(/assets/images/carousel/first.gif);
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 35%;
      height: 100%;
      max-width: 240px;
    }

    @media screen and (max-width: 1112px) {
      padding: 100px 20px 20px 20px;
      &:before {
        width: 50%;
        transform-origin: center;
      }
    }

    @media screen and (min-width: 1113px) and (max-width: 1366px) {
      padding: 100px 20px 20px 40px;
      &:before {
        // width: 50%;
        transform-origin: center;
      }
    }
}
}
#item-16 {
  .content {
    padding: 180px 36px 66px ;
    &:before {
      background-image: url(/assets/images/masonry/Feature_02.png);
      background-size: contain;
      background-repeat: no-repeat;
      //height:  128px;
      width: 70%; 
      height: 80px;
      top: 90px;
      // left: 50%;
      // transform: translateX(-50%);
      background-position: top center;


    }
    @media screen and (max-width: 1112px) {
      padding: 135px 20px 20px 20px;
      &:before {
        top: 34px;
        // left: 70%;
        // width: 100%;
      }
    }

  }

}

#item-3 {
  .content {
    padding: 38px 37px 20px 300px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/accessibility.png);
      background-size: contain;
      border-radius: 6px;
      background-repeat: no-repeat;
      width: 100%;
      height:  56%;
      top: 0;
      left:0;
      z-index: -1;
    }

    @media screen and (max-width: 321px) {
      padding: 225px 20px 20px 20px;
      &:before {
        height: 24%;
      }
    }

    @media screen and (min-width: 322px) and (max-width: 1112px) {
      padding: 225px 20px 20px 20px;
      &:before {
        height: 26%;
      }
    }
    
    @media screen and (min-width: 1113px) and (max-width: 1366px) {
      padding: 225px 20px 20px 20px;
      &:before {
        height: 28%;
      }
    }

    // @media screen and (max-width: 1366px) {
    //   &:before {
    //     height: 55%;
    //   }
    // }

  }

}

#item-4 {
  .content {
    padding: 48px 20px 20px 238px ;
    &:before {
      background-image: url(/assets/images/masonry/image-gallery.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 50%;
      height: 137px;
      max-width: 240px;

      top: 50%;
      left:0;
      transform: translateY(-50%);
    }
    @media screen and (max-width: 1112px) {
      padding: 100px 20px 20px 20px;
      &:before {
        top: 0;
        transform: none;
      }
    }

  }

}

#item-5 {
  .content {
    padding: 38px 37px 20px 300px ;
    &:before {
      background-image: url(/assets/images/masonry/html.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 35%;
      height: 100%;
      top: 55px;
      left: 2vw;
      max-width: 240px;
    }
    @media screen and (max-width: 1112px) {
      padding: 50px 20px 20px 20px;
      &:before {
        width: 27%;
        top: 14px;
        left: 70%;
      }
    }

  }

}
#item-6 {
  .content {
    padding: 75px 200px 20px 66px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/Feature_07.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 38%;
      height: 100%;
      top: 59%;
      left: 18vw;
      z-index: -1;
      border-radius: 6px;
    }
    @media screen and (max-width: 1112px) {
      padding: 217px 60px 20px 20px;
      &:before {
        width: 39%;
        left: 47%;
        top:4%;
      }
    }
  }

}

#item-7 {
  .content {
    padding: 146px 36px 36px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/Feature_08.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 80%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 240px;
    }
    @media screen and (max-width: 1112px) {
      padding: 72px 15px 20px 20px;
      &:before {
        width: 50%;
        transform-origin: center;
        left: 65%;
      }
    }


    @media screen and (min-width: 1113px) and (max-width: 1228px) {
      padding: 146px 15px 36px;
      z-index: 0;
      h4{
        font-size: 2.5vw;
      }
    }

  }

}

#item-8 {
  .content {
    padding: 90px 50px 93px 190px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/multi-lang.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 45%;
      top: 50%;
      left: 0;
      max-width: 240px;
      transform: translateY(-50%);
    }
    
    @media screen and (max-width: 667px) {
      padding: 268px 20px 20px 20px;
      &:before {
        width: 30%;
        height: 100%;
        transform: none;
        top: 0;
        left: 0;
      }
    }


  }

}

#item-9 {
  .content {
    padding: 60px 36px 30px;
    &:before {
      background-image: url(/assets/images/masonry/Feature_10.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 6px;
    }
    @media screen and (max-width: 1112px) {
      padding: 50px 20px 20px 20px;

    }

  }

}

#item-21 {
  .content {
    padding: 97px 66px 100px 192px;
    &:before {
      background-image: url(/assets/images/masonry/games.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 39%;
      top: 0;
      left: 40px;
      z-index: -1;
      border-radius: 6px;
    }
    @media screen and (max-width: 1112px) {
      padding: 176px 20px 20px 20px;
      &:before {
        height: 31%;
    }
  }

}
}

#item-22 {
  .content {
    padding: 97px 176px 100px 77px;
    &:before {
      background-image: url(/assets/images/masonry/graphic-powerhouse.png);
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 6px;
    }
    @media screen and (max-width: 1112px) {
      padding: 50px 20px 20px 20px;
    }

  }

}


#item-10 {
  .content {
    padding: 40px 20px 48px 260px;
    &:before {
      background-image: url(/assets/images/masonry/Feature_09.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 45%;
      height: 100%;
      top: 0;
      left: 0;
    }
    @media screen and (max-width: 1112px) {
      padding: 125px 20px 20px 20px;
      &:before {
        width: 50%;
        left: 50%;
      }
    }

  }

}

#item-11 {
  .content {
    padding: 86px 90px 93px 258px;
    &:before {
      background-image: url(/assets/images/masonry/xpub.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center right;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 6px;
    }
    // @media screen and (max-width: 1112px) {
    //   padding: 50px 120px 20px 20px;
    //   &:before {
    //     background-position-x: 25%;
    //   }
    // }

    @media screen and (max-width: 1600px) {
      padding: 60px 20px 20px 40px;
    }
  }


}

#item-12 {
  .content {
    padding: 86px 90px 93px 258px;
    &:before {
      background-image: url(/assets/images/masonry/coding.png);
      background-size: cover;
      background-repeat: no-repeat;
      top: 0;
      left: 0;
      width: 35%;
      height: 100%;
    }
    @media screen and (max-width: 1112px) {
      padding: 60px 20px 20px 20px;
    }

  }

}


#item-13 {
  .content {
    padding: 38px 37px 20px 230px ;
    &:before {
      background-image: url(/assets/images/masonry/Feature_13.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-position: 30px center;
    }
    @media screen and (max-width: 1112px) {
      padding: 50px 150px 20px 20px;
      &:before {
        width: 50%;
        background-size: cover;
        left: 100%;
        transform: translateX(-100%);
      }
    }

    // @media screen and (max-width: 640px) {
    //   padding: 50px 100px 20px 20px;

    // }
  }

}

#item-14 {
  .content {
    padding: 40px 20px 48px 240px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/masked-img.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left bottom ;
      width: 35%;
      height: 100%;
      top: 0;
      left: 0;
    }
    @media screen and (max-width: 1024px) {
      padding: 60px 20px 200px 20px;
      &:before {
        width: 70%;
      }
    }

  }

}

#item-15 {
  .content {
    padding: 99px 310px 36px 70px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/epub.png);
      background-size: contain;
      background-position: right bottom;
      background-repeat: no-repeat;
      width: 45%;
      height: 100%;
      top: 0;
      left: 100%;
      transform: translateX(-100%);
    }
    @media screen and (max-width: 1112px) {
      padding: 86px 94px 34px 20px;
      &:before {
        // top: 22px;
        // background-position: top; 

      }
    }

    @media screen and (max-width: 1366px) {
      padding: 86px 94px 34px 20px;
    }

  }

}
#item-2 {
  .content {
    //padding: 100px 36px 36px;
    padding: 200px 100px 36px 66px;
    &:before {
      background-image: url(/assets/images/masonry/interactivty.png);
      background-size: contain;
      background-repeat: no-repeat;
      //width: 70%;
      width: 45%;
      height: 100%;
      top: 0;
      left: 100%;
      transform: translateX(-100%);
      max-width: 240px;
    }
    @media screen and (max-width: 1112px) {
      padding: 100px 20px 20px 20px;
      &:before {
        width: 50%;
        transform-origin: center;
      }

    }

  }

}

#item-17 {
  .content {
    padding: 280px 36px 36px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/pan-zoom.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 60%;
      height: 100%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 1112px) {
      padding: 200px 20px 20px 20px;
      &:before {
        width: 38%;
        transform-origin: center;
      }

    }

  }

}


#item-18 {
  .content {
    padding: 38px 37px 20px 338px;
    &:before {
      background-image: url(/assets/images/masonry/typo.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 80%;
      left: 0;
      top: 0;
      // background-position: 15px center;
    }
    @media screen and (max-width: 1600px) {
      padding: 184px 20px 20px 20px;
      &:before {
        height: 36%;
        background-position: 0 center;
      }
    }

    // @media screen and (min-width: 1114x) and (max-width: 1600px){
    //   padding: 38px 37px 20px 80px;
    //   &:before {
    //     height: 42%;
    //   }
    // }

  }

}

#item-19 {
  .content {
    padding: 97px 220px 100px 44px;
    z-index: 0;
    &:before {
      background-image: url(/assets/images/masonry/iphone.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 60%;
      top: 50px;
      left: 0;
      z-index: -1;
      border-radius: 6px;
      background-position: right;

    }
    
    // @media screen and (min-width: 1920px) {
    //   padding-right: 350px;

    // }
    @media screen and (max-width: 1112px) {
      padding: 50px 120px 20px 20px;
      &:before {
        height: 40%;
      }

    }
    }
  }


#item-20 {
  .content {
    padding: 97px 220px 100px 66px;
    &:before {
      background-image: url(/assets/images/masonry/arrows.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 44%;
      top: 0;
      left: 62%;  
      z-index: -1;
      border-radius: 6px;
    }
    @media screen and (max-width: 1112px) {
      padding: 130px 20px 20px 20px;

    }
  }

}

  #item-1, #item-3, #item-5, #item-10, #item-12, #item-14, #item-19, #item-21, #item-23 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  #item-2, #item-4, #item-6, #item-11, #item-13, #item-15, #item-20, #item-22 {
    grid-column-start: 3;
    grid-column-end: 5;
  }



  #item-18 {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  #item-1, #item-2, #item-3, #item-6, #item-7, #item-8, #item-9, #item-11, #item-12,  #item-15, #item-16, #item-17, #item-19, #item-20 {
  //  grid-row-end: span 2;
  }




#item-1, #item-2 {
  grid-row-start: 1;
  grid-row-end: 3;
}

#item-3{
  grid-row-start: 3;
  grid-row-end: 5;
}

#item-4 {
  grid-row-start: 3;
  grid-row-end: 4;
}

#item-5 {
  grid-row-start: 5;
  grid-row-end: 6;
}

#item-6 {
  grid-row-start: 4;
  grid-row-end: 6;
}

#item-7 {
  grid-row-start: 6;
  grid-row-end: 8;
  grid-column-start: 1;
  grid-column-end: 2;
  @media screen and (max-width: 1112px) {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

#item-8 {
  grid-row-start: 6;
  grid-row-end: 8;
  grid-column-start: 2;
  grid-column-end: 4;
  @media screen and (max-width: 1112px) {
    grid-row-start: 7;
    grid-row-end: 8;
    grid-column-start: 1;
    grid-column-end: 5;
  }
}

#item-9 {
  grid-row-start: 6;
  grid-row-end: 8;
  grid-column-start: 4;
  grid-column-end: 5;
  @media screen and (max-width: 1112px) {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

#item-10 {
  grid-row-start: 10;
  grid-row-end: 11;
}

#item-11{
  grid-row-start: 10;
  grid-row-end: 12;
}

#item-12{
  grid-row-start: 11;
  grid-row-end: 13;
}

#item-13{
  grid-row-start: 12;
  grid-row-end: 13;
}
#item-14{
  grid-row-start: 13;
  grid-row-end: 14;
 }

#item-15{
  grid-row-start: 13;
  grid-row-end: 15;
  // @media screen and (max-width: 1024px) {
  //   grid-row-end: 14;

  // }

}

#item-16{
  grid-row-start: 14;
  grid-row-end: 16;
  grid-column-start: 1;
  grid-column-end: 2;
  @media screen and (max-width: 1112px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 14;
    grid-row-end: 15;
  }

}
#item-17{
  grid-row-start: 14;
  grid-row-end: 16;
  grid-column-start: 2;
  grid-column-end: 3;
  @media screen and (max-width: 1112px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 15;
    grid-row-end: 16;
  }
}

#item-18{
  grid-row-start: 15;
  grid-row-end: 16;
  // @media screen and (max-width: 1024px) {
  //   grid-column-start: 1;
  //   grid-column-end: 3;
  //   grid-row-end:17;
  //   grid-row-end:18;

  // }
}

#item-19{
  grid-row-start: 16;
  grid-row-end: 18;
}
#item-20{
  grid-row-start: 16;
  grid-row-end: 18;
}

#item-21{
  grid-row-start: 8;
  grid-row-end: 10;
}

#item-22{
  grid-row-start: 8;
  grid-row-end: 10;
}

#item-23{
  grid-row-start: 10;
  grid-row-end: 11;
}