//Barlow import
//@import url('https://fonts.googleapis.com/css?family=Barlow:400,700,900i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,700;0,900;1,400;1,900&display=swap');
//Circular import
@font-face {
  font-family: 'Circular Bold';
  src: url(./fonts/lineto-circular-bold.ttf) format('truetype');
}

$barlow: 'Barlow', sans-serif;
$circular: 'Circular Bold';
html {
  font-size: 16px;
}
body {
  font-family: $barlow;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $body_text_color;
  letter-spacing: 0.01px;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  @media screen and (max-width: 768px) {
    line-height: 31px;
  }
}


p {
  font-size: 1em;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
}
.nav-item {
  font-family: $circular;
  text-decoration: none;
  text-transform: uppercase;
  color: $body_text_color;
  font-size: .68em;
  line-height: .68em;
  letter-spacing: 1.4px;
}

h1 {
  font-family: $barlow;
  // font-weight: 900;
  margin-bottom: 100px;
  font-size: 7.5em;
  line-height: .9em;
  position: relative;
  z-index: 1;
  &.underline_deco {
    &:before {
      content: '';
      width: 100px;
      top: calc(100% + 50px);
    }

    @media screen and (max-width: 1024px) {
      font-size: 70px;
    }
  }
  &.smaller{
    font-size: 5em;
    @media screen and (max-width: 1024px) {
      font-size: 54px;
    }
  }
}

h2 {
  font-size: 3.125em;
  line-height: 1em;
  color: $body_text_color;
  font-family: $barlow;
  font-weight: 700;
  position: relative;
  margin-top: 0;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    font-size: 46px;
  }
}

h3 {
  font-family: $barlow;
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1em;
  position: relative;
  z-index: 1;
  @media screen and (max-width:1024px) {
    font-size: 36px;
  }

}

h4 {
  font-family: $barlow;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1em;
  z-index: 1;
}

.title-label {
  font-family: $circular;
  font-size: .68em;
  line-height: .875em;
  display: block;
  margin-bottom: 27px;
  text-transform: uppercase;
  position: relative;

  &.underline_deco {
    &:before {
      top: calc(100% + 10px);
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 14px;
  }
}

.underline_deco {
  &:before {
    content: '';
    height: 2px;
    width: 50px;
    position: absolute;
    top: calc(100% + 15px);
    background: $linear-gradient;
    background-size: 200% auto;
  }

  &.centered {
    &:before {
      left: 50%;
      transform: translateX(-50%);

    }
  }

  &.lefted {
    &:before {
      left: 0;

    }
  }

  &.align-center-mobile {
    &.lefted {
      @media screen and (max-width: 768px) {
        &:before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}



.clip {
  background-image: url("/assets/images/Bkg_Typo.jpg");
  // background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 110%;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: default;
}
p {
  a {
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
}


.post-wrap {
  h1 {
    font-weight: 700;
  }
  p, li {
    a {
      font-weight: 400;
      position: relative;
      text-decoration: underline;

    }
  }
}
