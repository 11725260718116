.header {
  background-color: $grey;
  margin-top: 30px;
  position: relative;
  //z-index: 11;
  .header-wrapper {
    //height: 40px;
    .logo-container {
      z-index: 1;
      img {
        display: block;
      }
    }
  }

  .nav-container {
    width: 100%;
    position: relative;
    z-index: 12;

    nav {
      float: right;

      .nav-item {
        margin: 0 20px;
        display: inline-block;
        position: relative;

        &:after {
          content: '';
          width: 0;
          height: 2px;
          background-color: $pink;
          position: absolute;
          top: -10px;
          left: 0;
          transition: width .5s linear;
          @media screen and (max-width: 1024px){
            content: none;
          }
        }

        &:hover {
          &:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $pink;
            position: absolute;
            top: -10px;
            left: 0;
            transition: width .5s linear;
            @media screen and (max-width: 1024px){
              content: none;
            }
          }
        }

        &.current {
          &:after {
            content: '';
            width: 100%;
            height: 2px;
            background-color: $pink;
            position: absolute;
            top: -10px;
            left: 0;
            transition: width .5s linear;
            @media screen and (max-width: 1024px){
              content: none;
            }
          }
        }
      }

    }

  }

  .submenu {
    width: calc(374px*3);
    justify-content: center;
    position: absolute;
    left: 110%;
    transform: translateX(-100%);
    z-index: -1;
    top: 50px;
    box-shadow: 0 9px 30px 0 rgba(0, 0, 0, 0.23);
    opacity: 0;
    height: auto;
    transition: opacity .5s ease-in-out;
    border-radius: 6px;
    overflow: hidden;
    .sub-item {
      background-size: 101%;
      background-position: bottom;
      background-repeat: no-repeat;
      text-align: center;
      height: 471px;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      padding-bottom: 25px;
      position: relative;
      width: 374px;
      display: none;
      z-index: -1;

      * {
        display: inline-block;
      }

      .title-container {
        width: 100%;
        background-color: white;
        padding: 25px 0 40px;
        transition: background-color .5s ease-in-out;

        * {
          width: 100%;
          font-weight: bold;
        }

        h3 {
          font-family: $barlow;
          font-size: 1.625em;
          font-weight: bold;
          letter-spacing: -0.25px;
          line-height: 0;
          margin-bottom: 0;
        }

        p {
          font-family: $circular;
          font-size: 0.75em;
          letter-spacing: 1.52px;
          line-height: 0.9375em;
          margin-top: 0;
          text-transform: uppercase;
        }
      }

      a {
        width: 60%;
        @media screen and (max-width: 1280px) {
          width: 80%;
        }
        z-index: 11;
        opacity: 0;
        transition: opacity .4s ease-in-out;

      }

      .logo {
        position: absolute;
        top: 90px;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        background-color: black;
        transition: opacity .4s ease-in-out;
      }

      &:hover {
        &:before {
          opacity: .1;
        }
        a {
          opacity: 1;
        }
      }

/*      &:nth-child(2) {
        &:after {
          content: '';
          background-color: transparent;
          width: 0;
          height: 0;
          border: 10px solid;
          border-left-color: transparent;
          border-right-color: transparent;
          border-top-color: transparent;
          border-bottom-color: white;
          position: absolute;
          left: 50%;
          top: -20px;
          z-index: 10;
          transition: border-bottom-color .5s ease-in-out;
        }

        &:hover {
          &:after {
            border-bottom-color: $grey_hover;
          }
        }
      }*/

    }

    &.active {
      opacity: 1;
      z-index: 20;
      .sub-item {
        display: flex;
      }
    }

    @media screen and (max-width: 1024px){
      width: 100vw;
      height: 100vh;
      background: #4C4C4C;
      left: 50%;
      top: 100%;
      transform: translate(50%, -100%);
      flex-direction: column;
      padding: 0 20px;
      justify-content: center;
      align-items: center;
      transition: transform .5s linear ;
      opacity: 1;
      z-index: 4;
      box-shadow: none;
      &.active {
        transform: translate(-50%, -100%);
        transition: transform .5s linear ;
        width: 100%;
      }
      .sub-item {
        background-size: 0;
        text-align: left;
        height: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        border-bottom: 1px solid white;
        padding-top: 21.5px;
        padding-bottom: 21.5px;

        * {
          display: inline-block;
        }

        .title-container {
          width: 100%;
          background-color: transparent;
          padding: 0;
          transition: background-color .5s ease-in-out;

          * {
            width: 100%;
            font-weight: bold;
            color: white;
          }

          h3 {
            font-size: 23px;
            line-height: 23px;
            margin-bottom: 0;
            margin-top: 0;
          }

          p {
            font-size: 11px;
            line-height: 1.2;
          }
        }

        a {
          width: 60%;
          z-index: 2;

        }

        .logo {
          position: relative;
          top: 0;
          margin-right: 10px;
          img {
            width: 110px;
            height: auto;
          }
        }

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          background-color: black;
          transition: opacity .4s ease-in-out;
        }

        &:hover {
          &:before {
            opacity: .2;
          }
        }

        &:nth-child(2) {
          &:after {
            content: none;
          }

          &:hover {
            &:after {
              border-bottom-color: $grey_hover;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 924px){
  .header {
    margin-top: 0;

    .logo-container {
      z-index: 1;
      width: auto;
      margin-right: auto;
      // display: none;
    }

    .menu-overlay {
      width: 100%;
      background-color: #4C4C4C;
      position: fixed;
      height: 100vh;
      z-index: 1;
      top: 0;
      left: 0;
      transform: translateX(100vw);
      transition: transform .25s linear;

    }

    .header-wrapper {
    }

    .nav-container {
      height: 80vh;
      margin-top: 60px;
      display: none;
      transform: translateX(100vw);
      transition: transform .25s linear;
      z-index: 2;

      nav {
        float: none;
        text-align: center;

        .nav-item {
          margin: 35px auto;
          display: block;
          position: relative;
          width: 50%;
          color: white;
          font-size: 15px;
        }
      }

    }

    #trialBtn {
      opacity: 0;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .5s linear, opacity .5s linear .4s;
      z-index: 3;
      width: 100%;
      text-align: center;
    }

    &.menu-open {
      height: 100vh;
      position: fixed;
      width: 100%;
      max-width: 100%;
      top: 0;
      z-index: 999999;
      .menu-overlay {
        transform: translateX(0vw);
      }

      .header-wrapper {
      }

      .nav-container {
        display: block;
        transform: translateX(0vw);
      }

      #trialBtn {
        opacity: 1;
        transform: scaleX(1);
        margin-top: 100px;
        a {
          width: 300px;
        }
      }

      .hamburger {
        span {
          background-color: white;
        }
      }
    }
  }
  .has-children {
    &:before {
      content: url(/assets/images/chevron.png);
      height: 10px;
      transform: rotate(180deg);
      position: absolute;
      top: 5px;
      left: 90%;
    }
  }
}


// hamburger menu
.hamburger {
  width: 30px;
  height: 15px;
  position: absolute;
  float: right;
  top: 34px;
  right: 25px;
  display: inline-block;
  z-index: 11;

  span {
   /* height: 2px;
    display: block;
    background-color: $body_text_color;
    position: absolute;
    left: 0;
    transition: all .2s cubic-bezier(0.445, 0.05, 0.55, 0.95);*/
    display: block;
    width: 100%;
    height: 2px;
    background-color: $body_text_color;
    position: relative;
    transition: all .2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    & + span {
      margin-top: 7px;
    }
    &:first-child {
      width: 28px;
      animation: ease .4s top-2 forwards;

    }

    &:nth-child(2) {
      width: 22px;
      animation: ease .4s scaled-2 forwards;
    }

    &:last-child {
      animation: ease .4s bottom-2 forwards;
    }

  }

  &.opened {
    span {
      &:first-child {
        width: 100%;
        animation: ease .4s top forwards;
      }
      &:nth-child(2) {
        animation: ease .4s scaled forwards;
      }

      &:last-child {
        animation: ease .4s bottom forwards;
      }
    }

  }

  @media screen and (min-width: 925px) {
    display: none;
  }
}

.chevron {
  width: 23px;
  height: 35px;
  position: absolute;
  top: 30px;
  left: 25px;
  display: inline-block;
  z-index: 11;
  color: white;
  font-size: 36px;
  @media screen and (min-width: 1025px) {
    display: none;
  }

}

@media screen and (max-width: 1124px) {
  .menu-open {
    .crop {
      width: 30px;
      height: 44px;
      overflow: hidden;
      display: none;
    }
  }

}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 100%;
    transform: rotate(0);
  }
  100% {
    top: 100%;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 100%;
    transform: rotate(45deg);
  }
  50% {
    top: 100%;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 3px;
    transform: rotate(0);
  }
  100% {
    bottom: 3px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 3px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 3px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


#back {
  display: none;
}