.btn {
  padding: 0 32px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  font-family: $circular;
  text-transform: uppercase;
  font-size: .75em;
  line-height: 50px;
  width: 190px;
  margin: 0 5px;
  z-index: 3;
  position: relative;
  letter-spacing: 1.52px;
  transition: all .6s ease-in-out;
  &:hover {
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.07);
  }
  @media screen and (max-width:768px) {
    width: 100%;
    max-width: 100%;
    display: block;
    margin: 10px auto;
  }
}
.gradient_btn {
  background-image: $linear-gradient;
  background-size: 200% auto;
  color: white;
  &:visited {
    color: white;
  }
  &:hover {
    background-position: right center;
  }
}

.white_btn {
  background: #fff;
  color: $body_text_color;
  position: relative;
  &:hover {
    .text-btn {
      animation: backgroundAnimation .2s linear;
      background: $linear-gradient;
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.black_btn {
  background: $body_text_color;
  color: #fff;
  &:visited {
    color: white;
  }

}
.border_btn {
  border: 1px solid white;
  background: transparent;
  color: white;
  transition: all .5s linear;
  &:visited {
    color: white;
  }
  &:hover {
    background-color: white;
    .text-btn {
      animation: backgroundAnimation 1s ease-in-out;
      background: $linear-gradient;
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.minus-plus{
  position: relative;
  width: 15px;
  height: 15px;
  display: inline-block;
  &:before,
  &:after{
    content: "";
    position: absolute;
    background: $linear-gradient ;
    background-size: 200% auto;
    transition: transform 0.6s ease-in-out;
  }
  &:before{
    top: 0;
    left: 50%;
    width: 3px;
    height: 100%;
    margin-left: -1.5px;
  }

  &:after{
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    margin-top: -1.5px;
  }


}
.gradient-text {
  &:hover {
    animation: backgroundAnimation 1s ease-in-out;
    background: $linear-gradient;
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}


@keyframes backgroundAnimation {
  0% {
    background-position: center center;
  }
  100% {
   background-position: center center;
        }
  50% {
    background-position: right center;
  }
}


button {
  border-radius: 6px ;
}
