#desktop-software {
  padding-top: 100px ;
  position: relative;
  padding-bottom: 10vw;
  .content-container {
    //padding-left: 100px;
    margin: auto 64px auto auto;
    max-width: 400px;
    @media screen and (max-width: 1024px) and (min-width: 769px) {
      margin-right: 0;
    }
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-top: 80px;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .gradient_btn {
    margin-top: 48px;
    display: inline-block;
  }
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 100vw solid transparent;
    border-right: 100vw solid transparent;
    border-bottom: 25vw solid $grey;
    @media screen and (max-width: 768px) {
      border-bottom: 100px solid $grey;
    }
    position: absolute;
    bottom: 0;
    z-index: -1;
  }
  @media screen and (max-width:768px) {
    padding-top: 0;
    margin-top: 54px;
    padding-bottom: 160px;
    .content-container {
      max-width: 85%;
      margin-left:  auto;
      margin-right:  auto;
    }
  }



  .intro {
    padding-bottom: 90px;

  }
  .pubLogo {
    max-width: 77px;
    margin-bottom: 0;
  }
}

.pubLogo {
     width:auto;
   }

.oneLine{
  padding: 0 25px;
}