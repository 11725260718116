.hero {
  position: relative;
  padding-top: 50px;
  padding-bottom: 100px;
  min-height: 60vh;
  position: relative;

  .hero_wrapper {
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width: 768px) {
      max-width: 85%;
    }
  }

  p {
    font-size: 1.25em;
  }
  .trial-triggers {
    padding-top: 20px;
    p {
      font-size: 0.875em;
      letter-spacing: 0.01px;
      line-height: 20px;
      margin-bottom: 0;
      margin-top: 0;
    }
    a {
      color: $pink;
      &:hover {
        font-weight: bold;
      }
    }
  }

  .social-conversa {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    padding-bottom: 100px;
  }


  .text-animation {
    height: auto;
  }

}


// svg

#claim {
  position:relative;
  top:0;
  left:0;
  width:100%;
  height:100%;
  cursor: default;
  margin: 0;
  line-height:  0.85em;
  font-size: 12vw;
  padding-bottom: 10px;

  // @media screen and (max-width: 1850px) {
  //   font-size: 12em;
  // }
  // @media screen and (max-width: 1736px) {
  //   font-size: 10.5em;
  // }
  // @media screen and (max-width: 1439px) {
  //   font-size: 10em;
  // }
  // @media screen and (max-width: 1366px) {
  //   font-size: 9.5em;
  // }
  // @media screen and (max-width: 1280px) {
  //   font-size: 7.5em;
  // }
  // @media screen and (max-width: 1024px) {
  //   font-size: 6em;
  // }
  // @media screen and (max-width: 640px) {
  //   font-size: 4em;
  // }
  // @media screen and (max-width: 567px) {
  //   font-size: 3em;
  // }
  // @media screen and (max-width: 400px) {
  //   font-size: 2.5em;
  // }


    font-family:$barlow;
    font-weight:900;
    font-style: italic;
  //  /* @media screen and (max-width: 1399px) {
  //     transform: scale(.9) translateX(0px)
  //   }
  //   @media screen and (max-width: 1200px) {
  //     transform: scale(.8) translateX(0px)
  //   }
  //   @media screen and (max-width: 1024px) {
  //     transform: scale(.6) translateX(0px)
  //   }
  //   @media screen and (max-width: 800px) {
  //   transform: scale(.5) translateX(0px)
  // }
  //   @media screen and (max-width: 650px) {
  //     transform: scale(.25) translateX(0px)
  //   }*/


}


#heroWrap {
  position: relative;
  z-index: 8;
  height: 100%;
}




.social-conversa {
  img {
    transition: opacity .5s ease-in-out;
  }
  a {
    &:hover {
      img {
        opacity: .5;
      }
    }
  }
}