
// features card

.masonry-container-single {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 9;
  padding-bottom: 100px;
  @media screen and (max-width: 1440px) {
    max-width: 90%;
  }
}

.category {
  font-size: 0.6875em;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  margin-bottom: 27px;
  display: inline-block;
  font-family: $circular;
}

.masonry-single {
  display: grid;
  grid-template-columns: minmax(150px, 1fr);
  grid-template-rows: repeat(5, minmax(300px, auto));
  grid-gap: 20px;
}

@media screen and (max-width: 1024px) {
  .masonry-single {
    grid-gap: 0;
  }
}

@media screen and (max-width: 680px) {
  .masonry-single {
    display: block;
  }
}

.masonry-single .item {
  width: 100%;
  position: relative;
  overflow: hidden; /* Assicura che l'overflow venga nascosto */
  

  @media screen and (max-width: 1200px) {
    padding: 15px;
    flex: 1 0 auto;
    overflow: hidden;
    //width: 50%;
  }
  @media screen and (max-width: 700px) {
    flex: 1 0 auto;
    overflow: hidden;
  }
  &.light-text {
    color: #fff
  }
  .content {
    width: 100%;
    min-height: 604px !important;
    position: relative;
    min-height: 100px;
    padding: 20px;
    z-index: 0;
    background-color: white;
    border-radius: 6px;
    overflow: hidden;

    @media screen and (max-width: 820px) {
      min-height: 500px !important;

    }


    &:before {
      content: '';
      position: absolute;
      height: auto;
      z-index: -1;
    }
  }
    .category {
      font-size: 0.6875em;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      margin-bottom: 27px;
      display: inline-block;
      font-family: $circular;
    }

    h4 {
      margin-top: 0;
      margin-bottom: 14px;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: -0.29px;
      line-height: 30px;
    }

}


#item1, #item3, #item5{
  .content {
    padding: 83px 60% 36px 70px;
  }

  @media screen and (max-width: 820px) {
    .content {
      padding: 20px;
    }
  }
}

#item2, #item4{
  .content {
    padding: 86px 90px 93px 55%
  }

  @media screen and (max-width: 820px) {
    .content {
      padding: 20px;
    }
  }
}

#item1{
  .content{
    &:before {
      background-image: url(/assets/images/masonry_single/ipad.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 69%;
      height: 100%;
      top: 0px;
      right: -109px;
      transform: scale(1.2); 

    }

    @media screen and (max-width: 520px) {
      padding: 240px 20px 20px 20px;
      &:before {
        width: 84%;
        left: 9vw;
        top: 20px;
        /* transform-origin: center; */
        transform: scale(1);
      }
    }

    @media screen and (min-width: 521px) and (max-width: 820px) {
      padding: 240px 20px 20px 20px;
      &:before {
        width: 84%;
        left: 9vw;
        top: -86px;
        /* transform-origin: center; */
        transform: scale(1);
      }
    }
  }
  
}

#item2{
  .content{
    &:before {
      background-image: url(/assets/images/masonry_single/store.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 61%;
      height: 100%;
      top: 24%;
      left: -139px;
    }
    @media screen and (max-width: 820px) {
      padding: 20px 20px 200px 20px;
      &:before {
        width: 100%;
        left: 0vw;
        // bottom: 20px;
        /* transform-origin: center; */
        transform: scale(1);
        top: 70%;
      }

    }
  }
  
}

#item3{
  .content{
    &:before {
      background-image: url(/assets/images/masonry_single/iphones.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 56%;
      height: 100%;
      right: 0;
      top: 33%;
      bottom: 0;
    }

    @media screen and (max-width: 820px) {
      padding: 20px 20px 240px 20px;
      &:before {
        width: 90%;
        right: 0vw;
        /* transform-origin: center; */
        transform: scale(1);
        top: 81%;
        bottom: 0;
      }

      #ibooks-icon{
        left: -38px !important;
      }
    }

    @media screen and (min-width: 821px) and (max-width: 1209px){
      &:before {
      top: 56%;
      }
  }
}
  
}

#item4{
  .content{
    &:before {
      background-image: url(/assets/images/masonry_single/off.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 69%;
      height: 100%;
      left: -58px;
      transform: scale(1.1);
    }

    @media screen and (max-width: 820px) {
      padding: 200px 20px 20px 20px;
      &:before {
        width: 100%;
        top: 47px;
        left: -17px;
        /* transform-origin: center; */
        transform: scale(1.1);
      }
    }

    @media screen and (min-width: 500px) and (max-width: 820px) {
      padding: 240px 20px 20px 20px;
      &:before {
        width: 100%;
        top: -10px;
        left: -17px;
        /* transform-origin: center; */
        transform: scale(1.1);
      }
    }
  }
}

#item5{
  .content{
    &:before {
      background-image: url(/assets/images/masonry_single/pubcoder-button.png);
      background-size: contain;
      background-repeat: no-repeat;
      width: 59%;
      height: 119%;
      right: -50px;
      top: 20px;
      transform: scale(1.3);
    }

    @media screen and (max-width: 376px) {
      padding: 20px 20px 190px 20px;
        &:before {
          width: 100%;
          left: -10%;
          transform: scale(0.7);
          top: 53%;
      }
    }

    @media screen and (min-width: 377px) and (max-width: 648px) {
      padding: 20px 20px 190px 20px;
        &:before {
          width: 100%;
          left: -10%;
          /* transform-origin: center; */
          transform: scale(0.5);
          top: 37%;
        }

    }

    @media screen and (min-width: 649px)  and (max-width: 820px) {
      padding: 20px 20px 260px 20px;
      &:before {
        width: 100%;
        left: -10%;
        /* transform-origin: center; */
        transform: scale(0.7);
        top: 27%;
      }

    }

    @media screen and (min-width: 821px) and (max-width: 1299px){
      &:before {
        width: 59%;
        height: 119%;
        right: -50px;
        top: 167px;
        transform: scale(1.3);
      }
    }
  }
}


// form card

.personal-textarea {
  max-width: 100%;
  width: 413px;
  /* height: 219px; */
  min-width: 100px;
  min-height: 40px;
  max-height: 100px;
}

input, select, textarea{
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 100% */
  letter-spacing: 0.007px;
}

input:focus {
  outline: none;
}

select:focus {
  outline: transparent;
}

textarea:focus{
  outline: none;
}

::placeholder {
  color: black; /* Sostituisci #999 con il colore desiderato */
}

.div-block-7{
  padding: 0 10px;
}

.form-block {
  margin: 0 auto;
  width: 100%;
  min-height: 170px;
}

.form-block .w-row {
  margin-bottom: 20px; /* Spazio tra le righe */
  display: flex;
  flex-wrap: wrap;
}

.form-block .w-col {
  padding: 0 10px; /* Spazio tra le colonne */
  width: 50%;

}

.form-block .field-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.form-block .text-field,
.form-block .select-field,
.form-block .textarea {
  width: 100%;
  padding: 10px 0px 10px 0px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid black;
  height: 40px;
  color: black;
}

.form-block .checkbox {
  margin-top: 20px;
}

.form-block .checkbox-field {
  display: flex;
  align-items: center;
}

.form-block .checkbox-label {
  font-size: 14px;
  margin-left: 5px;
}

/* Messaggio di successo */
.success-message {
  display: none;
  margin-top: 20px;
}
.error-message {
  display: none;
  margin-top: 20px;
}

.success-message img {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.success-message .text-block-7 {
  display: inline-block;
  vertical-align: middle;
}