
.slideInRight {
  animation: slideInRight 1s linear;
}

.slideInLeft {
  animation: slideInRight 1s linear;
}

.fadeIn {
  animation: fadeIn 1s linear;
}

@keyframes slideInRight {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;;
  }
}

// page transition

.top-layer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  bottom: auto;
  right: auto;
  background: $pink;
  transition: all 0.9s cubic-bezier(0.6, 0.05, 0.4, 1);
  z-index: 999;
  &.active {
    top: 100%;
  }
}
.top-layer--2 {
  background: #ffffff;
  transition-delay: 0.6s;
}
.top-layer--3 {
  background: $linear-gradient;
  transition-delay: 0.4s;
}
.top-layer--4 {
  background: #ffffff;
  transition-delay: 0.2s;
}

.top-layer--5 {
  background: $pink;
  transition-delay: 0.1s;
}
