.container {
  @media screen and (min-width: 769px) {
    max-width: 95vw;
  }
  width: 100%;
  margin: 0 auto
}

.col-100 {
  width: 100%;
}
.col-70 {
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}
.col-60 {
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}

.col-50 {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}
.col-40 {
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}

.col-30 {
  width: 30%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}

.col-20 {
  width: 20%;
  @media screen and (max-width: 768px) {
    width: 100%
  }
}
@media screen and (max-width: 1024px) {

  .tablet-full-width {
    width: 100%;
  }
  .tablet-column {
    flex-direction: column;
  }
  .tab-no-pad {
    padding: 0
  }
}
@media screen and (max-width: 768px) {
  .column-reverse {
    flex-direction: column-reverse;
  }
  .column {
    flex-direction: column;
  }
  .mobile-full-width {
    width: 100%;
  }
}



.stretch {
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  width: 100%;
  position: relative;
  z-index: 1;


  @media screen and (max-width: 800px) {
    max-width: 600px;
  }

  @media screen and (max-width: 750px) {
    max-width: 400px;
  }
  @media screen and (max-width: 430px) {
    max-width: 320px;
  }
}