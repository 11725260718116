.scheda {
  border-radius: 6px;
  position: relative;
  height: 585px;
  transition: background-color .5s linear;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, .5);

  }

  .content {
    padding: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    p, h3 {
      cursor: default;
    }
  h3 {
    margin-top: 24px;
    margin-bottom: 0;
  }
    .price-image {
      background-size: 0;
      background-position: center;
      background-repeat: no-repeat;
      transition: background-size .4s cubic-bezier(0.47, 0, 0.745, 0.715);

      img {
        width: 100%;
        filter: grayscale(100%);
      }
    }


    .button-container {
      opacity: 0;
      margin-top: 0;
      transition: opacity .8s linear;

      .btn {
        width: 190px;
        display: block;

        &:last-child {
          margin-top: 15px;
        }
      }
    }

    &:hover {
      .button-container {
        opacity: .5;
      }

      .spec {
        opacity: .5;
      }
    }
  }

  &.active {
    background-color: #fff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.07);
    .content {
      .price-image {
        background-image: url(/assets/images/Standard_bg.png);
        background-size: 100%;

        img {
          filter: none;
        }
      }

      .button-container {
        opacity: 1;
      }
    }

    .spec {
      opacity: .5;
    }
  }
}

.larger {
  font-size: 2.5em;
}

.price {
  font-size: 0.8125em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}


.more {
  font-size: 0.625em;
  opacity: .5;
  text-transform: uppercase;
  transition: opacity .3s linear;

  &:hover {
    opacity: 1;
  }
}

.spec {
  font-size: 0.625em;
  opacity: 0;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
letter-spacing: 1.65px;
}

#Free {
  .button-container {
    margin-top: 2px;

    .btn {
      &:last-child {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .scroll-container {
    position: relative;
    overflow: hidden;
  }
  #pricingCarousel {
    position: relative;
    .owl-nav {
      display: block;
      font-size: 45px;
      font-weight: 300;
      width: 35%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      margin-top: 28px;
      .owl-next {
        float: right;
      }
      .owl-prev {
        float: left;
      }
    }

    .owl-dots {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      .owl-dot {
        background: #333;
        opacity: .23;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        margin: 0 2.5px;
        &.active {
          opacity: 1;
        }
      }
    }
    &.flex-wrapper {
      display: block;
      flex-direction: row;
    }
    .flex-wrapper {
      @media screen and (max-width: 768px) {
        display: block;
      }
    }

    &.maxwidth80 {
      max-width: 100%;
    }

    .scheda {
      display: inline-block;
      max-width: 90vw;
      margin: auto;
      background-color: #fff;
      .content {
        white-space: normal;
      }
    }

  }
}
#pricingCarousel {
  padding-bottom: 20vw;
  .owl-carousel {
    @media screen and (min-width: 769px) {
      display: flex;
    }
    @media screen and (min-width: 769px)  and (max-width: 1024px){
      max-width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .maxwidth80 {
      max-width: 100%;
    }
  }
}

.card-large{
  position: relative;
  z-index: 1;
  margin-bottom: 54px;
  border-radius: 6px;
  height: 616px;
  @media screen and (max-width: 640px) {
    height: 944px;
  }
  .col-50 {
    padding: 90px 30px 120px;
    @media screen and (max-width: 640px) {
      padding: 60px 30px 60px;
    }
    @media screen and (max-width: 768px) and (min-width: 640px) {
      width: 60%;
    }
  }
  p {
    max-width: 473px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  h3 {
    max-width: 473px;
    margin: 20px auto;
  }
  .btn {
    width: auto;
    margin-top: 43px;
  }
  .img-bg {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    width: 52%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  @media screen and (max-width: 768px) {
    .img-bg {
      position: relative;
      transform: none;
      left: 0;
      img {
        transform: rotate(90deg);
        width: 86%;
      }
    }
  }

}
#discoverShelf {
  background: linear-gradient(90deg, #FD7059 0%, #FB3385 100%);
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .col-50 {
      width: 70%;
      padding: 15px 30px;
    }
  }
  @media screen and (max-width: 640px) {
    padding-top: 35vh;
  }
  &:before {
    content: '';
    width: 50%;
    height: 100%;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    background-image: url(/assets/images/discover-shelf.png);
    @media screen and (max-width: 1024px) {
      width: 100%;
      background-position-x: 293%;
    }
    @media screen and (max-width: 768px) {
      background-position-x: 385%;
    }
    @media screen and (max-width: 640px) {
      background-image: url(/assets/images/Shelf_mob_2.png);
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

#edu {
  margin-top: -10vw;
  background-image: url('/assets/images/Edu_desktop.jpg');
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    background-position: 20%;
    .col-50 {
      width: 70%;
      padding: 15px 30px;
    }
  }
  @media screen and (max-width: 650px) {
    background-image: url('/assets/images/PubCoder_Edu_mobile.jpg');
    background-position: bottom;
    background-size: cover;
  }
}


.pricing {
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    .container-padding {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

}