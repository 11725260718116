#customers {

}
.logos {
  padding: 100px 0;
  @media screen and (max-width: 1024px) and (min-width: 769px) {
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 34px 0 100px;
    img {
      margin-bottom: 40px;
    }
  }
}