$grey: #F0F0F0;
$grey_hover: rgba(216,216,216, 02);
$body_text_color: #333;
$pink: #EF2A70;
$orange: #FD7059;

$linear-gradient: linear-gradient(to right, #FD7059 0%, #FB3385 100%);
$linear-gradient-opposite: linear-gradient(to right, #FB3385 0%, #FD7059 100%);



