.accordion {
  min-height: 500px;

  .accordion-item {
    border-top: 1px solid $body_text_color;
    position: relative;
    cursor: default;

    .question {
      font-size: 1.25em;
      margin: 0;
      padding: 16px 0;
      position: relative;
      height: 100px;
      display: flex;
      width: 100%;
      align-items: center;
      @media screen and (max-width: 768px) {
        padding: 32px 0;
        height: auto;
      }
  .question-text {

      max-width: 90%;
      display: inline-block;

  }
      .icon-container {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &.expanded {
        .icon-container {
          .minus-plus {
            &:before {
              transform: rotate(90deg);
            }

            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }

    }

    .answer {
      display: none;
      margin: 0;
      padding: 16px 0;
      max-width: 90%;

    }

    &:last-child {
      border-bottom: 1px solid $body_text_color;
    }
  }

}

@media screen and (max-width: 768px) {
  .accordion-container {
    margin-top: 57px;
  }

}
