#discover {
  margin-top: 77px;

  .content {
    background: $linear-gradient;
    background-size: 200% auto;
    padding-right: 0;
    @media screen and (min-width: 769px) {
      padding-top: 0;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 0;
      .align-center {
        padding-left: 20px;
        padding-right: 20px;
      }
    }


    .col-50 {
      &:first-child {
        align-self: center;
        padding-right: 80px;
        @media screen and (min-width: 769px) and (max-width: 1024px) {
          padding-right: 0;
        }
        @media screen and (max-width: 768px) {
          text-align: center;
          padding-left: 100px;
          padding-right: 100px;
        }
        @media screen and (max-width: 640px) {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      &:last-child {
        padding-bottom: 30px;

      }
    }

  }
  margin-bottom: 26px;
.white_btn {
  margin-top: 60px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 60px;
  }
}
  h3 {
    margin-top: 0;
  }
.pubLogo {
  @media screen and (max-width: 768px) {
    position: relative;
    margin-top: -80px;
  }
}
  @media screen and (min-width: 641px)  and (max-width: 768px) {

    .img-container {
      height: 640px;
      margin-bottom: 0;
      img {
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }

  }
}

#shelf {
  .content {
    background-color: $body_text_color;
  }

  margin-right: 13px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 26px;
  }
}

#reader {
  .content {
    background-color: #FFEC00;
  }

  margin-left: 16px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

#discover-shelf {
  .content {
    background-color: $body_text_color;
  }

  margin-right: 13px;
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 26px;
  }
}

#contact-question {
  .content {
    background-color: #FFEC00;
  }

  margin-left: 16px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.image-content-bg{
  background-image: url(/assets/images/masonry_single/pinkmask.png);
  background-size: cover; /* Fa sì che l'immagine copra l'intero sfondo senza ripetersi */
  background-position: center; /* Centra l'immagine di sfondo */
}

.cta_home {
  padding-top: 130px;
  margin-bottom: 150px;
  position: relative;
  .flex-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: auto;
  }
  .single-cta {
    position: relative;
    z-index: 1;
    .content {
      height: 100%;
      border-radius: 6px;
      padding: 50px 82px 0;
      @media screen and (max-width: 1280px) {
        padding: 20px 20px 0;
      }
      @media screen and (max-width: 768px) {
        padding: 60px 100px 0;
      }
      @media screen and (max-width: 640px) {
        padding: 20px 20px 0;
      }

    }
    .btn {
      width: auto;
       @media screen and (max-width: 768px) {
         width: 300px;
       }
      @media screen and (max-width: 640px) {
        width: 100%;
      }

    }
    &.col-50 {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        @media screen and (min-width: 769px) {
          h3 {
            height: 90px;
          }
          p {
            height: 75px;
          }
        }

        a {
          margin-top: 40px;
          margin-bottom: 40px;
        }
        .img-container {
          position: relative;
          display: table-cell;
          vertical-align: bottom;
        }
      }
    }
  }

  .img-container {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 170vw solid transparent;
    border-bottom: 47vw solid #F0F0F0;
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    transform: scale(-1);
    @media screen and (max-width: 768px) {
      border-bottom: 100px solid $grey;
      left: 0;
    }
  }
  .icone {
    height: 80px;
  }
}

.cta_home-noborder {
  padding-top: 130px;
  margin-bottom: 150px;
  position: relative;
  .flex-wrapper {
    max-width: 1440px;
    width: 100%;
    margin: auto;
  }
  .single-cta {
    position: relative;
    z-index: 1;
    .content {
      height: 100%;
      border-radius: 6px;
      padding: 50px 82px 0;
      @media screen and (max-width: 1280px) {
        padding: 20px 20px 0;
      }
      @media screen and (max-width: 768px) {
        padding: 60px 100px 0;
      }
      @media screen and (max-width: 640px) {
        padding: 20px 20px 0;
      }

    }
    .btn {
      width: auto;
       @media screen and (max-width: 768px) {
         width: 300px;
       }
      @media screen and (max-width: 640px) {
        width: 100%;
      }

    }
    &.col-50 {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: flex-end;
        @media screen and (min-width: 769px) {
          h3 {
            height: 90px;
          }
          p {
            // height: 75px;
          }
        }

        a {
          margin-top: 40px;
          margin-bottom: 40px;
        }
        .img-container {
          position: relative;
          display: table-cell;
          vertical-align: bottom;
        }
      }
    }
  }

  .img-container {
    img {
      width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 170vw solid transparent;
    // border-bottom: 47vw solid #F0F0F0;
    position: absolute;
    top: 0;
    left: -12px;
    z-index: -1;
    transform: scale(-1);
    @media screen and (max-width: 768px) {
      border-bottom: 100px solid $grey;
      left: 0;
    }
  }
  .icone {
    height: 80px;
  }
}

