.home {
  .card {
    width: auto;
    border-radius: 4px;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.07);
    background-color: #fff;
    margin-bottom: 45px;
    .card-img {
      width: 100%;
      height: 280px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .descr {
      padding: 30px;
      height: 250px;
      @media screen and (max-width: 600px) {
        height: 300px;
      }
    }
    a {
      font-size: 0.625em;
      text-transform: uppercase;
      font-family: $circular;
      font-weight: bold;
    }
    @media screen and (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 30px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07);

    }

  }

}
#features_carousel {
  margin: 50px 0;
  .owl-nav {
    position: absolute;
    top: 42%;
    transform: translateY(-42%);
    left: 165px;
    button {
      position: relative;
      width: 52px;
      height: 52px;
      background: #fff !important;
      border-radius: 100%;
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);
      span {
        font-size: 25px;
      }
      &:first-child {
        margin-right: 5px;
      }
    }
    @media screen and (max-width: 1024px) {
      left: 2%;
    }
    @media screen and (max-width: 768px) {
      margin-top: 40px;
      width: 50%;
      display: block;
      right: 0;
      left: 50%;
      top: 87%;
      transform: translateX(-50%);
      button {
        background: transparent !important;
        box-shadow: none;
      }
      .owl-next {
        float: right;
      }
      .owl-prev {
        float: left;
      }
    }
    @media screen and (max-width: 600px) {
      top: 90%
    }
  }

  .owl-dots {
    position: absolute;
    top: 96%;
    transform: translateX(-50%);
    left: 50%;
    @media screen and (max-width: 768px) {
      .owl-dot {
        width: 5px;
        height: 5px;
        background: #333;
        opacity: .32;
        margin: 0 2.5px;
        border-radius: 50%;
        &.active {
          opacity: 1;
        }
      }
    }
    @media screen and (max-width: 600px) {
      top: 98%
    }
  }
  .card {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
}

.features_wrapper {
  background-color: $grey;
  padding: 50px 0;
  position: relative;
  /*   @media screen and (max-width: 768px) {
       padding-left: 7.5%;
       padding-right: 7.5%;
     }*/
  .flex-wrapper {
    align-items: flex-end;
  }
  .col-50 {
    &.align-left {
      margin-left: 10px;
      p {
        max-width: 392px;
      }
    }
    &.align-right {
      margin-right: 10px
    }

  }
  @media screen and (max-width: 768px) {
    .white_btn {
      background-image: $linear-gradient;
      transition: 0.5s;
      background-size: 200% auto;
      //transition: all .5s linear;
      width: 90%;
      color: white;
      &:visited {
        color: white;
      }
      &:hover {
        background-position: right center;
        //color: $body_text_color
        //transition: all .5s linear;
      }
    }
  }
}


// nav-item query for space


  #trialBtn{
    @media screen and (min-width: 925px) and (max-width: 1289px) {
    display: none !important;
    position: absolute;
    left: -9999px;
    top: -9999px;
}
}

.nav-container{
  @media screen and (min-width: 925px) and (max-width: 1127px) {
      .id7{
        display: none !important;
        position: absolute;
        left: -9999px;
        top: -9999px;
    }
  }
}
