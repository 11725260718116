// Slider mobile version
.slider-mockup-container {
  margin: 0 auto;
  position: relative;

  &.is-mobile {
    width: 55%;
    @media screen and (max-width: 1366px) {
      width: 90%;
    }
    @media screen and (max-width: 768px) {
      width: 360px;
    }

    &.is-landscape {
      width: 80%;
    }
  }

  &.is-tablet {
    display: none;
    width: 90%;
    margin-bottom: 100px;
  }

  .owl-nav {
    height: 52px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;

    button {
      height: 100%;
      width: 52px;
      border-radius: 50%;
      border: none;
      position: absolute;
      cursor: pointer;
      background-color: #fff;

      &.owl-prev {
        left: 0;
      }

      &.owl-next {
        right: 0;
      }
    }

    @media screen and (max-width: 1000px) {
      top: 100%;
      transform: none;
      button {
        position: relative;
        margin: 5px;
        background-color: $grey;
      }
    }
  }

  &.is-landscape {
    .the_phone {
     // width: 813px;
     // height: 375px;
      height: 400px;
      width:  763px;
      background-repeat: no-repeat;
      background-image: url(/assets/images/iphone_landscape.png);
      background-size: contain;
      top: 5px;
      @media screen and (max-width: 1024px) {
        width: 540px;
        height: 270px;
        top: 15px;
      }
      @media screen and (max-width: 768px) {
        width: 360px;
        height: 190px;
      }
    }

    .the_tablet {
      width: 1110px;
      height: 785px;
      background-image: url(/assets/images/ipad_landscape.png);

      &:active {
        cursor: grabbing;
      }

      @media screen and (max-width: 1439px) {
        width: 1016px;
        height: 710px;
      }
      @media screen and (max-width: 1280px) {
        width: 1024px;
        height: 640px;
      }
      @media screen and (max-width: 1100px) {
        width: 615px;
        height: 429px;
      }

      @media screen and (max-width: 500px) {
        width: 350px;
        height: 220px;
      }
    }

    .dm-width {
      margin: 0 auto;

      &.phone {
        width: 780px;
      }

      &.tablet {
        width: 1055px;
      }

      @media screen and (max-width: 1280px) {
        &.tablet {
          width: 955px;
        }
      }
      @media screen and (max-width: 1100px) {
        &.tablet {
          width: 630px;
        }
      }
      @media screen and (max-width: 1024px) {
        &.phone {
          width: 590px;
        }
      }
      @media screen and (max-width: 1000px) {
        &.tablet {
          width: 665px;
        }
      }
      @media screen and (max-width: 768px) {
        &.phone {
          width: 410px;
        }
      }
      @media screen and (max-width: 500px) {
        &.tablet {
          width: 350px;
        }
      }
    }

  }
}

.the_phone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 375px;
  height: 763px;
  background-repeat: no-repeat;
  background-image: url(/assets/images/iphone_portrait.png);
  background-size: contain;
  background-position: center center;
  z-index: 9;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media screen and (max-width: 1024px) {
    width: 375px;

  }
  @media screen and (max-width: 768px) {
    width: 324px;
    height: auto;
  }
}

.the_tablet {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1110px;
  width: 785px;
  background-repeat: no-repeat;
  background-image: url(/assets/images/ipad.png);
  background-size: contain;
  background-position: center center;
  z-index: 9;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @media screen and (max-width: 1366px) {
    //width: 768px;
    //height: 1024px;
    width: 810px;
    height: 1145px;
  }
  @media screen and (max-width: 1024px) {
    width: 600px;
    height: 750px;
  }

  @media screen and (max-width: 500px) {
    width: 350px;
    height: 220px;
  }
}

.dm-width {
  margin: 0 auto;

  &.phone {
    width: 386px;
  }

  &.tablet {
    width: 750px;
  }

  @media screen and (max-width: 1366px) {
    &.tablet {
      width: 768px;
    }
  }
  @media screen and (max-width: 1024px) {
    &.phone {
      width: 420px;
    }
  }
  @media screen and (max-width: 1024px) {
    &.tablet {
      width: 520px;
    }
  }
  @media screen and (max-width: 768px) {
    &.phone {
      width: 360px;
    }
  }
  @media screen and (max-width: 500px) {
    &.tablet {
      width: 350px;
    }
  }
}

.is-mobile {
  .iphone-mockup {
    position: relative;
    z-index: 5;
  }

  .dm-device {
    position: relative;
    width: 100%;
    padding-bottom: 198.477897%;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px) {
      padding-bottom: 192.477897%;
    }
  }

  .device {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .screen {
    overflow: hidden;
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    border-radius: 30px;
    @media screen and (max-width: 1024px) {
      bottom: 76px
    }
    @media screen and (max-width: 768px) {
      bottom: 45px;
    }
  }

  .slider {
    height: 100%;

  }

  .slider div {
    height: 100%;
  }

  .slider__item {
    font-size: 100px;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-landscape {
    .dm-device {
      padding-bottom: 52%;
      @media screen and (max-width: 1024px) {
        padding-bottom: 60.477897%;
      }
      @media screen and (max-width: 768px) {
        padding-bottom: 57.477897%;
      }
    }
  }
}

.is-tablet {
  .iphone-mockup {
    position: relative;
    z-index: 5;
  }

  .dm-device {
    position: relative;
    width: 100%;
    padding-bottom: 148.477897%;
    margin-bottom: 20px;
    @media screen and (max-width: 1024px){
      padding-bottom: 144%;
    }
    @media screen and (max-width: 768px) {
      padding-bottom: 154%;
    }
  }

  .device {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .screen {
    overflow: hidden;
    position: absolute;
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    //@media screen and (max-width: 1366px) {
    //  bottom: 140px;
    //}
    @media screen and (max-width: 1000px) {
      top: 20px
    }
    @media screen and (max-width: 768px) {
      bottom: 45px;
    }
    @media screen and (max-width: 768px) {
      bottom: 65px;
    }
    @media screen and (max-width: 500px) {
      bottom: 50px;
      top: 10px;
    }
  }

  .slider {
    height: 100%;

  }

  .slider div {
    height: 100%;
  }

  .slider__item {
    font-size: 100px;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.is-landscape {
    .dm-device {
      padding-bottom: 72.477897%;
      @media screen and (max-width: 1439px) {
        padding-bottom: 66%;
      }
      @media screen and (max-width: 1100px) {
        padding-bottom: 70%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .is-tablet {
    display: none;
  }
}


// Buttons downlowad

.buttons-download {
  display: flex;
  justify-content: center;
  align-items: center;

  .os {

    display: inline-block;
    vertical-align: middle;
    padding: 0 20px;
    margin-bottom: 40px;

    p {
      font-size: 0.8125em;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

#post-nav {
  position: relative;
  z-index: 1;

  .arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

    }
  }

  .prev, .next {
    font-family: $circular;
    font-size: 1.5em;
    letter-spacing: 1.52px;
    color: #323232;
    text-transform: lowercase;
    width: 175px;
    span img {
      width: 15px;
    }
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 73px;
  height: 34px;
  z-index: 1;
  margin-bottom: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background: $linear-gradient;
    -webkit-transition: .4s;
    transition: .4s;
  }
}


input:checked + .switch-round {
  background: #fff;
}

input:focus + .switch-round {
  box-shadow: 0 0 1px $pink;
}

input:checked + .switch-round:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(38px);
}


.is-overlapped {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 18vw;
    background: white;
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
  }

  &.higher {
    &:before {
      height: 8vw;
    }

    @media screen and (max-width: 1024px) {
      &:before {
        height: 12vw;
      }
    }
  }
}

.screen {
  background: $linear-gradient;
}




.single-item-case {
  position: relative;
  z-index: 1;
  //width: 14.583vw;
  width: 100%;
  display: inline-block;
  //border-radius: 6px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  .image-wrapper {
    height:100%;
    width: 100%;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding-bottom: 0;
    margin-bottom: 0;
    //@media screen and (max-width: 1024px) {
    //  height: 45vw;
    //}
    //@media screen and (max-width: 768px) {
    //  height: 335px;
    //}
    //@media screen and (max-width: 600px) {
    //  height: 50vh;
    //}
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: $linear-gradient;
      opacity: 0;
      //z-index: -1;
      border-radius: 6px;
      transform: translateY(100%);
      transition: all 0.5s ease-in-out;

    }
    img {
      height: auto;
      width: 100%;
      margin: 0 auto;
      display: inline-block;

    }

    .over-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      text-align: center;
      transform: translateY(100%);
      opacity: 0;
      transition: all 0.5s ease-in-out;

      p {
        font-family: $barlow;
        font-size: 1.111vw;
        letter-spacing: 0.1px;
        line-height: 1.5;
        text-transform: none;
        color: #fff;
        @media screen and (max-width: 1024px) {
          width: 100%;
          font-size: 1em;
        }
        @media screen and (max-width: 1024px) {
          font-size: 18px;
        }
      }

      .category-label {
        color: #fff;
        text-transform: uppercase;
        font-size: 0.733vw;
        font-family: $circular;
        font-weight: 600;
        letter-spacing: 1.52px;
        margin-bottom: 45px;
        display: inline-block;
        @media screen and (max-width: 1024px) {
          font-size: 0.7em;
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
      }

      a {
        display: inline-block;
        color: #fff;
        font-family: $circular;
        // font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.3);
        font-size: .75em;
        letter-spacing: 1.52px;
        padding: 10px 14px;
        border-radius: 34px;

        &:hover {
          opacity: 0.8;
        }

        @media screen and (max-width: 1024px) {
          font-size: 0.8em;
        }
        @media screen and (max-width: 1024px) {
          font-size: 15px;
        }
      }

      @media screen and (max-width: 599px) {
        padding-left: 60px;
        padding-right: 60px;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  h4 {
    font-family: $barlow;
    font-weight: 600;
    font-size: 1.15em;
    line-height: 23px;
    letter-spacing: 0.18px;
    color: #333333;
    margin-bottom: 5px;
    margin-top: 35px;
  }

  p {
    margin-top: 0;
    color: #333333;
    font-size: 0.7em;
    line-height: 14px;
    letter-spacing: 1.4px;
    font-family: $circular;
    font-weight: 300;
    text-transform: uppercase;

  }

  &:hover {
    .image-wrapper {
      .over-content {
        transform: translateY(0);
        opacity: 1;
        transition: all 0.5s ease-in-out;
      }
        &:before {
         opacity: 1;
          transform: translateY(0);
          transition: all 0.5s ease-in-out;
      }
    }
  }
}


// Testimonials
#testimonial_carousel {
  .owl-item {
    background-color: #f0f0f0;
    height: 470px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 105px;
    position: relative;
    @media screen and (max-width: 1100px) {
      padding: 0 80px;
      height: 430px;
    }
    @media screen and (max-width: 1024px) {
      padding: 0 50px 0 70px;
      height: 400px;
    }
    @media screen and (max-width: 768px) {
      padding: 0 50px 0 70px;
      height: 300px;
    }

  }

  .item {
    position: relative;
  }

  .quote {
    font-size: 1.6em;
    line-height: 32px;
    letter-spacing: 0.01px;
    margin-top: 40px;
    @media screen and (max-width: 1280px) {
      font-size: 1.4em;
      line-height: 28px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 1.2em;
      line-height: 23px;
    }

    p {
      position: relative;
      height: 100%;
      display: inline-block;

      &:before, &:after {
        content: '"';
        width: 30px;
        position: absolute;
        font-size: 2em;
        font-style: italic;
        background: $linear-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:before {
        top: 10px;
        left: -35px;
        transform: rotate(180deg);
        transform-origin: top;
      }

      &:after {
        bottom: 0;
        transform: translateY(20px);
        margin-left: 5px;
      }
    }
  }

  .author {
    font-family: $circular;
    font-weight: 600;
    font-size: 0.7em;
    letter-spacing: 1px;
    opacity: 0.8;
    @media screen and (max-width: 1280px) {
      font-size: 0.6em;
    }
  }

}

// Home cases



#testimonial_carousel, #case-studies {
  .owl-nav {
    position: absolute;
    top: 42%;
    transform: translateY(-42%);
    left: 20px;
    @media screen and (min-width: 1600px) {
      left: 12%;
    }

    button {
      position: relative;
      width: 52px;
      height: 52px;
      background: #fff !important;
      border-radius: 100%;
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1);

      &:first-child {
        margin-right: 5px;
      }

      font-size: 25px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 40px;
      width: 50%;
      display: block;
      right: 0;
      left: 50%;
      top: 87%;
      transform: translateX(-50%);
      button {
        background: transparent !important;
        box-shadow: none;
      }
      .owl-next {
        float: right;
      }
      .owl-prev {
        float: left;
      }
    }
    @media screen and (max-width: 600px) {
      top: 100%;
      height: 52px;
      margin: 0;
    }
  }

  .owl-dots {
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    align-items: center;
    height: 52px;
    @media screen and (max-width: 768px) {
      .owl-dot {
        width: 5px;
        height: 5px;
        background: #333;
        opacity: .32;
        margin: 0 2.5px;
        border-radius: 50%;

        &.active {
          opacity: 1;
        }
      }
    }
    @media screen and (max-width: 600px) {

    }
  }
}

@media screen and (max-width: 1024px) {
  .no-padding-mobile {
    padding: 0;
  }
}

#case-studies {
  position: relative;
  z-index: 1;
  margin-bottom: 150px;
  @media screen and (max-width: 768px) {
    margin-bottom: 150px;
  }
  .flex-wrapper {
    justify-content: space-between;
  }
  .owl-carousel .single-item-title {
      text-align: center;
  }
  .owl-carousel .owl-item img {
    width: 100%;
    //display: inline-block;
    object-fit: contain;
  }
  .owl-nav {
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 50%;
    top: 100%;
    margin-top: 50px;
    transform: translateX(-50%);
    button {
      background: #333 !important;
      span {
        color: #fff;
      }
    }
    //@media screen and (max-width: 768px) {
    //
    //    width: 95%;
    //    display: flex;
    //    justify-content: space-between;
    //    align-items: center;
    //    left: 50%;
    //    transform: translate(-50%, -50%);
    //    top: 50%;
    //    margin-top: 0;
    //  button {
    //    background: #fff !important;
    //  }
    //}
  }
  .owl-dots {
    @media screen and (max-width: 600px) {
      margin-top: 50px;
    }
  }
}
.switch-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 25%;
  margin: auto;
  @media screen and (max-width: 1024px) {
    width: 50%;
  }

  .type_device {
    position: relative;
    display: inline-block;
    color: #323232;
    font-family: Circular,sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.52px;
    width: 120px;
    z-index: 1;
    margin-bottom: 40px;
    height: 34px;
    line-height: 34px;
    opacity: 0.5;
    transition: all 0.4s ease-in-out;

    &:first-child {
      text-align: left;
    }

    &.active {
      opacity: 1;
      transition: all 0.4s ease-in-out;
    }
    @media screen and (max-width: 600px) {

      display: none;
    }
  }
}
/* Mobile behaviour */
.mobile-control {
  .mobile-cover {
    max-width: 90%;
    margin: auto;
    width: 100%;
    display: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 80%;
      width: 60%;
      border: none;
    }
  }

  .close-carousel-mobile {
    display: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: -1;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }

  @media screen and (max-width: 600px), (max-height: 600px) and (orientation: landscape) {
    .mobile-cover {
      display: block;
      position: relative;
      z-index: 1;
    }

    .switch, .type_device {
      display: none;
    }
    .close-carousel-mobile {
      display: flex;
      opacity: 0;
    }
    .slider-mockup-container {
      &.tablet-mockup.is-tablet {
        display: none;
        visibility: hidden;
      }

      &.is-mobile {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: -1;
        display: none;
        opacity: 0;

        .the_phone {
          display: none;
        }

        .screen {
          border-radius: 0;
          bottom: 0;
          right: 0;
          left: 0;
          top: 0;
          background: none;
        }
      }
    }
    .dm-width.phone {
      width: 100vw;
    }
    .owl-nav {
      position: fixed;
      top: 85%;
      z-index: 112;
      height: 50px;
      button {
        background: #fff;
      }
    }
    .is-landscape .dm-width.phone {
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey_hover
    }
    .is-mobile .dm-device {
      padding-bottom: 0;
      height: 100vh;
      margin-bottom: 0;
    }
  }
  .is-mobile.is-landscape {
    .slider__item {
      img {
        object-fit: contain ;
      }
    }
  }
}

.boxed {
  max-width: 70%;
  @media screen and (min-width: 1600px) {
    max-width: 50%;
  }
}


@media screen and (max-width: 600px) {
  .is-mobile {
    .slider__item {
      img {
        object-fit: cover ;
      }
    }
  }
  .is-mobile.is-landscape {
    .slider__item {
      img {
        object-fit: contain ;
      }
    }
  }
}